/**
 * 经纪人管理-经纪人信息-客户信息
 * luxinwen
 * 2023-03-22
 */
<style scoped lang="less">
  @import "./list.less";
</style>

<template>
  <div class="page">
    <div class="page-main">
      <!-- <div class="page-main-thead client">
        <div class="client-title">客户量</div>
        <div class="client-info">
          <div class="client-info-item">
            <span>推荐客户</span>
            <p>36人</p>
          </div>
          <div class="client-info-item">
            <span>到访客户</span>
            <p>36人</p>
          </div>
          <div class="client-info-item">
            <span>认购客户</span>
            <p>36人</p>
          </div>
          <div class="client-info-item">
            <span>成交客户</span>
            <p>36人</p>
          </div>
        </div>
      </div> -->
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="status">
            <span>{{ statusList[params.row.status] }}</span>
          </template>
          <template slot-scope="params" slot="operation">
            <Button @click="showData(params.row)" v-grant="'OSP_BROKER_CUSTOMER_VIEW'">查看</Button>
            <Button @click="showCommission(params.row)" v-grant="'OSP_BROKER_CUSTOMER_COMMISSION'">佣金信息</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <edit-drawer v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag" @success="getData"></edit-drawer>
    <commission-info v-model="commissionDisplay" :data="commissionData"></commission-info>
  </div>
</template>

<script>
  import { ACTIVITY_PART_STATUS } from '@/util/enum';
  import editDrawer from './edit.vue';
  import commissionInfo from '@/views/commission/mgm/info.vue';

  export default {
    components: {
      editDrawer,
      commissionInfo
    },
    data() {
      return {
        tableColumns: [
          {
            title: '客户名称',
            key: 'customerName'
          },
          {
            title: '客户手机号',
            key: 'phone'
          },
          {
            title: '推荐时间',
            key: 'recommendTime'
          },
          {
            title: '活动名称',
            key: 'activityName'
          },
          {
            title: '状态',
            slot: 'status'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'add',    // 编辑抽屉状态
        commissionDisplay: false,
        commissionData: {},
        dataId: '',
        statusList: ACTIVITY_PART_STATUS
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_BROKER_CUSTOMER_VIEW, this.$grant.OSP_BROKER_CUSTOMER_COMMISSION];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.dataId = this.$route.query.memberId;
      if (this.dataId) this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, {
          memberId: this.dataId,
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        this.$axios({
          url: this.$api.member.queryCustomerList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 查看
       */
      showData(row) {
        this.drawerFlag = 'show';
        this.drawerData = row;
        this.drawerDisplay = true;
      },
      /**
       * 佣金信息
       * @param  {[type]} row [description]
       * @return {[type]}     [description]
       */
      showCommission(row) {
        this.commissionData = {
          id: row.id
        };
        this.commissionDisplay = true;
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>